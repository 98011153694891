<script lang="ts" setup>
import { VueFinalModal } from 'vue-final-modal'

const emit = defineEmits<{
  close: []
  confirm: [type: 'new' | 'amendment']
}>()

const router = useRouter()
const divisionId = getCurrentDivisionId()
const leaseId = getCurrentLeaseId()

const isNewLeaseSelected = ref(false)
const isCopyingLease = ref(false)

const onNewLeaseCreation = () => {
  isCopyingLease.value = true
  setTimeout(() => {
    isCopyingLease.value = false
    emit('confirm', 'new')
    const url = `/division/${divisionId}/lease/${leaseId}/manager`
    router.push(url)
  }, 1200)
}

const onAddAmendment = () => {
  emit('confirm', 'amendment')
  const url = `/division/${divisionId}/lease/${leaseId}/amendments`
  router.push(url)
}
</script>
<template>
  <VueFinalModal
    class="flex justify-center bg-black/60 transition-all"
    content-class="w-full"
    overlay-transition="vfm-fade"
  >
    <ModalContainer
      title="Option Exercise"
      close-on-click
      class="h-screen"
      container-class="max-w-[94vw] w-[600px]"
      @close="() => emit('close')"
    >
      <div v-auto-animate class="grid gap-8">
        <div v-if="!isNewLeaseSelected" class="grid gap-4">
          <div class="grid gap-2">
            Exercise this option through a variation
            <span class="text-sm text-gray-400">
              (keep the same Lease ID)
            </span>

            <div>
              <Button
                icon="plus"
                color="opacityPrimary"
                class="inline-flex"
                @click="onAddAmendment"
              >
                Variation
              </Button>
            </div>
          </div>

          <hr class="bg-gray-750 my-2 h-px border-t-0" />

          <div class="grid gap-2">
            Exercise this option through a New Lease
            <span class="text-sm text-gray-400">
              (a new Lease ID will be created)
            </span>

            <div>
              <Button
                icon="plus"
                color="opacityPrimary"
                @click="isNewLeaseSelected = true"
              >
                New lease
              </Button>
            </div>
          </div>

          <div class="flex justify-end gap-2">
            <Button
              v-if="!isNewLeaseSelected"
              color="opacitySecondary"
              @click="() => emit('close')"
            >
              Cancel
            </Button>
          </div>
        </div>

        <!-- Add new lease for -->
        <div v-else class="grid gap-4">
          <FormKit type="form" :actions="false" @submit="onNewLeaseCreation">
            <FormKit
              type="datepicker"
              label="Execution Date"
              validation="required"
            />

            <div class="mb-2 mt-4 flex justify-between">
              <Button
                :disabled="isCopyingLease"
                :class="{
                  'pointer-events-none opacity-50': isCopyingLease,
                }"
                color="opacitySecondary"
                @click="isNewLeaseSelected = false"
              >
                back
              </Button>

              <Button :loading="isCopyingLease" color="primary" type="submit">
                {{ isCopyingLease ? 'Copying lease...' : 'Confirm' }}
              </Button>
            </div>
          </FormKit>
        </div>
      </div>
    </ModalContainer>
  </VueFinalModal>
</template>
